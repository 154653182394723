import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBoolean, useStateList } from 'react-use';

// import { TREE_ID, USER_ID } from 'lib/apiClient';
import { getAssignmentInfoFromStorage, toSnakeCase } from 'lib/utils';

import { PostInterestsPayload } from 'types';

import useGetInterests from 'api/useGetInterestsLms';
import useInterestCollected from 'api/useInterestsCollectedLMS';
import usePostInterests from 'api/usePostInterestsGoLive';
import useSendInterests from 'api/useSendInterests';

import useUserLSState from 'hooks/useUserLSState';

import AnswerOption from 'components/ui/AnswerOption';
import Audio, { AudioRef, BASE_URL } from 'components/ui/Audio';
import ContentLuca from 'components/ui/ContentLuca';
import { FormControl, FormControlTarget } from 'components/ui/FormGroup';

import { URLS } from 'components/App';
import Feed from 'components/Feed';

import * as Styled from './styles';

const getAudioFile = (index: number) => `questions/q${index + 1}.mp3`;
// Preudo (mocked) data from BE
const STEPS = [
  {
    id: 0,
    questions: [
      {
        id: 0,
        text: 'Hi! Nice to meet you!',
        audio: getAudioFile(2),
      },
      {
        id: 1,
        text: 'My name is Luca. I really like adventures and making new friends.',
        audio: getAudioFile(3),
      },
    ],
    step: 'greetings',
  },
  {
    id: 1,
    questions: [
      {
        id: 0,
        text: 'Do you want to go on an adventure with me?',
        audio: getAudioFile(4),
      },
    ],
    answers: ['Yes, of course'],
    step: 'intro',
  },
  {
    id: 2,
    questions: [
      {
        id: 0,
        text: 'If you could go on any adventure, where would you go?',
        audio: getAudioFile(5),
      },
      {
        id: 1,
        text: 'You can select a few places!',
        audio: getAudioFile(6),
      },
    ],
    answers: ['Mountains', 'Ocean', 'Forest', 'Magic land', 'Big city', 'Space'],
    step: 'places',
  },
  {
    id: 3,
    questions: [
      {
        id: 0,
        text: 'That sounds amazing! I want to go too!',
        audio: getAudioFile(7),
      },
      {
        id: 1,
        text: 'What would be the most exciting part of this adventure?',
        audio: getAudioFile(8),
      },
    ],
    answers: ['Pirates', 'Aliens', 'Animals', 'Magic', 'Starship', 'Stars'],
    step: 'characters',
  },
  {
    id: 4,
    questions: [
      {
        id: 0,
        text: 'An incredible adventure awaits us!',
        audio: getAudioFile(9),
      },
      {
        id: 1,
        text: 'Give me just a minute to get ready.',
        audio: getAudioFile(10),
      },
    ],
    step: 'outro',
  },
];

const Questions = () => {
  const { data: interestQuestions } = useGetInterests();
  const { userState, updateState } = useUserLSState();
  const navigate = useNavigate();
  const [isFeedFull, setIsFeedFull] = useBoolean(false);
  const { state, prev, next, currentIndex } = useStateList(
    STEPS.map((step) => ({
      ...step,
      questions: step.questions.map((question) => ({
        ...question,
        text:
          step.id === 0 && question.id === 0
            ? question.text.replace('{{name}}', userState?.name ?? 'Kid')
            : question.text,
        id: `${step.id}_${question.id}`,
      })),
    }))
  );

  const [interests, setInterests] = useState<Record<string, string[] | null>>({});

  const answerOptionAudioRef = useRef<AudioRef>(null);

  const sendUserInterestsMutation = useSendInterests();
  const interestsCollected = useInterestCollected();
  const postInterests = usePostInterests();

  const stepHasAnswers = useMemo(
    () => !!state.answers?.length,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentIndex]
  );

  const goToPrevStep = () => {
    if (state.answers && (state.step === 'places' || state.step === 'characters')) {
      updateState(state.step, []);
      setInterests((prev) => ({ ...prev, [state.step]: [] }));
    }
    return currentIndex === 0 ? navigate(URLS.selectAgeRange) : prev();
  };

  const goToNextStep = () => {
    if (state.answers && (state.step === 'places' || state.step === 'characters')) {
      updateState(state.step, interests[state.step] ?? []);
    }
    const optionsFiltered = interestQuestions?.interestScreens.map((item) => ({
      contents: item.contents.filter((content) => content.hasOwnProperty('questionId')),
    }));
    const optionsWithQuestionId = optionsFiltered
      ?.filter((item) => item.contents.some((content) => content.hasOwnProperty('questionId')))
      .map((item) => item.contents)
      .flat(1)
      .map((item) => item.questionId);
    if (currentIndex === STEPS.length - 1) {
      const { assignmentId } = getAssignmentInfoFromStorage();
      const goLivePayload: PostInterestsPayload = {
        assignmentId: assignmentId,
        interests: [
          {
            answers: interests.places ?? [],
            questionId: String(optionsWithQuestionId?.at(0)) ?? '',
          },
          {
            answers: interests.places ?? [],
            questionId: String(optionsWithQuestionId?.at(1)) ?? '',
          },
        ],
      };
      // Outdated mutation - keeping for reference
      // const payload: InterestPayload = {
      //   userId: USER_ID,
      //   name: userState?.name ?? '',
      //   ageRange: userState?.ageRange ?? '',
      //   questionTree: {
      //     treeId: TREE_ID,
      //     questions: { qId1: interests.places ?? [], qId2: interests.characters ?? [] },
      //   },
      // };
      interestsCollected.mutate();
      postInterests.mutate(goLivePayload, {
        onSuccess: () => {
          updateState('menu', {
            story: 'ready',
            lesson: 'ready',
          });
          navigate(URLS.menu);
        },
      });
      // Outdated mutation - keeping for reference
      // sendUserInterestsMutation.mutate(payload, {
      //   onSuccess: () => {
      //     updateState('menu', {
      //       story: 'ready',
      //       lesson: 'ready',
      //     });
      //     navigate(URLS.menu);
      //   },
      // });
    } else {
      next();
    }
  };

  const playAnswerOption = (folder: string, option: string) => {
    const url = `${BASE_URL}questions/${folder}/${option}.mp3`;
    answerOptionAudioRef.current?.setSrc(url);
  };

  const handleChange =
    (type: string) =>
    ({ name }: FormControlTarget) =>
      setInterests((prev) => {
        const prevInterests = prev[type] ?? [];
        const selected = prevInterests.includes(name);

        if (!selected) {
          const option = toSnakeCase(name, { lowercase: true });
          playAnswerOption(type, option);
        }

        const newInterests = selected ? prevInterests.filter((int) => int !== name) : prevInterests.concat(name);
        return { ...prev, [type]: newInterests };
      });

  const handleFeedFull = () => setIsFeedFull(true);

  const nextButtonVisible = useMemo(() => {
    const currentStep = STEPS[currentIndex]?.step;
    const isStepHasInterests = !!interests?.[currentStep]?.length;
    return (!stepHasAnswers && isFeedFull) || (!!Object.keys(interests).length && isStepHasInterests);
  }, [currentIndex, interests, isFeedFull, stepHasAnswers]);

  const prevButtonVisible = useMemo(() => {
    return !(currentIndex === 0);
  }, [currentIndex]);

  return (
    <ContentLuca
      onBack={prevButtonVisible ? goToPrevStep : undefined}
      onForward={nextButtonVisible ? goToNextStep : undefined}
      onForwardProps={{ disabled: sendUserInterestsMutation.isLoading }}
    >
      <Feed phrases={state.questions} onFeedFull={handleFeedFull} key={`Feed_${currentIndex}`}>
        {stepHasAnswers && (
          <Styled.QuestionsDialog position="right">
            {state.answers?.length === 1 ? (
              <Styled.FormGroupSingle type="radio" onChange={goToNextStep}>
                <FormControl name="answer">
                  <AnswerOption>{state.answers[0]}</AnswerOption>
                </FormControl>
              </Styled.FormGroupSingle>
            ) : (
              <>
                <Styled.FormGroupMultiple type="checkbox" onChange={handleChange(state.step)}>
                  {state.answers?.map((answer) => (
                    <FormControl defaultChecked={interests[state.step]?.includes(answer)} name={answer} key={answer}>
                      <AnswerOption>{answer}</AnswerOption>
                    </FormControl>
                  ))}
                </Styled.FormGroupMultiple>
                <Audio ref={answerOptionAudioRef} />
              </>
            )}
          </Styled.QuestionsDialog>
        )}
      </Feed>
    </ContentLuca>
  );
};

export default Questions;
